import { AxiosResponse } from 'axios';
import { CustomerInsightsResponse, GetCustomerInsightsResponse } from 'src/models/response/GetCustomerInsightsResponse';
import { GetReviewTrendsResponse } from 'src/models/response/GetCustomerTrendsResponse';
import { ApiService } from './apiService';

export class ReviewDashboardService {

  static async getReviewHistory(range: string[]): Promise<AxiosResponse<GetReviewTrendsResponse[]>> {
    return ApiService.api
      .get(`/ReviewDashboard/GetReviewHistory?from=${range[0]}&to=${range[1]}&timezone=${range[2]}`);
  }

  static async getCustomerInsights(range: string[]): Promise<AxiosResponse<CustomerInsightsResponse[]>> {
    return ApiService.api
      .get(`/ReviewDashboard/GetCustomerInsights?from=${range[0]}&to=${range[1]}&timezone=${range[2]}&source=${range[3]}`);
  }

  static async getCustomerImprovements(range: string[]): Promise<AxiosResponse<number[]>> {
    return ApiService.api
      .get(`/Dashboard/GetCustomerImporvementData?from=${range[0]}&to=${range[1]}&timezone=${range[2]}`);
  }
}
