import { AxiosResponse } from 'axios';
import { getDate } from 'date-fns';
import workspaces from 'src/content/management/Users/workspaces';
import { Tag } from 'src/models/mailbox';
import {
  ConfigurationRequest,
  KeyValue
} from 'src/models/request/configurationRequests';
import { GenerateReplyRequest } from 'src/models/request/generateReplyRequest';
import { PagingParams } from 'src/models/request/pagination';
import { SendReplyRequest } from 'src/models/request/sendReplyRequest';
import { WorkspaceUserRequest } from 'src/models/request/workspaceRequests';
import { BoardsGetEmailDataResponse } from 'src/models/response/BoardsGetDataResponse';
import { GetCustomerTrendsResponse } from 'src/models/response/GetCustomerTrendsResponse';
import {
  WorkspaceDetails,
  WorkspaceUser
} from 'src/models/response/WorkspaceReponse';
import { User, UserResponse, UserRole } from 'src/models/user';
import { AuthService } from 'src/services/authService';
import { AvgCalculatingService } from 'src/services/avgCalculatingService';
import { BoardsService } from 'src/services/boardsService';
import { configurationService } from 'src/services/configurationService';
import { DashboardService } from 'src/services/dashboardService';
import { EmailService } from 'src/services/emailService';
import { userService } from 'src/services/userService';
import { WorkspacesService } from 'src/services/workspaceService';
import create from 'zustand';

import { devtools, persist } from 'zustand/middleware';
import { useAuthStore } from './authStore';

export interface SelectInput {
  title: string;
}
const trends = [
  { title: 'Unchanged' },
  { title: 'Improving' },
  { title: 'Escalating' }
];

const status = [
  { title: 'New' },
  { title: 'In Progress' },
  { title: 'Resolved' }
];

export interface GorgiasSignature {
  id?: string;
  header: string;
  signature: string;
  name: string;
}
export interface UsersStore {
  users: User[];
  workspaceDetails?: WorkspaceDetails;
  workspaceUsers?: WorkspaceUser[];
  configurations?: KeyValue[];
  gorgiasSignatures?: GorgiasSignature[];
  //actions

  getUsers: () => void;
  updateRoles: (roles: string, userid: string) => void;
  addWorkspace: (name: string) => void;
  getWorkspaceDetails: () => void;
  addUserToWorkspace: (request: WorkspaceUserRequest) => void;
  removeUserFromWorkspace: (userId: string) => void;
  getWorkspaceUsers: () => void;
  getConfigurations: () => void;
  addorUpdateConfig: (config: KeyValue) => void;
  removeConfig: (key: string) => void;

  getGorgiasSubscriptions: () => void;
  addorUpdateGorgiasSubscription: (sign: GorgiasSignature) => void;
}
export const useUsersStore = create<UsersStore>()(
  devtools(
    persist((set, get) => ({
      users: [],
      workspaceDetails: null,
      workspaceUsers: null,
      configurations: [],
      gorgiasSignatures: [],
      getUsers: async () => {
        const result = await userService.getUsers();
        if (result.status === 200) {
          var userResult: User[] = result.data.map((user) => {
            return {
              id: user.id,
              email: user.email,
              name: user.name,
              plan: '',
              roles: user.roles.split(',').map((x) => {
                x as UserRole;
              }) as unknown as UserRole[],
              noOfIneractionsUsed: 0,
              noOfMaxInteractions: 0
            };
          });
          set({ users: userResult });
        }
      },
      updateRoles: async (roles: string, userid: string) => {
        var result = await userService.updateRoles(roles, userid);
      },
      addWorkspace: async (name: string) => {
        var result = await WorkspacesService.addWorkspace(name);
        if (result.status == 200) {
          const updateUserWorkspaceDetails =
            useAuthStore.getState().updateUserWorkspaceDetails;
          updateUserWorkspaceDetails(result.data);
        }
      },

      getWorkspaceDetails: async () => {
        const result = await WorkspacesService.getWorkspaceDetails();
        set({ workspaceDetails: result.data });
      },
      addUserToWorkspace: async (request: WorkspaceUserRequest) => {
        const result = await WorkspacesService.addUserToWorkspace(request);
        if (result.status == 200) {
          get().getWorkspaceDetails();
        }
      },
      removeUserFromWorkspace: async (userId: string) => {
        const result = await WorkspacesService.removeUserFromWorkspace(userId);
        if (result.status == 200) {
          get().getWorkspaceDetails();
        }
      },
      getWorkspaceUsers: async () => {
        const result = await WorkspacesService.getWorkspaceUsers();
        if (result.status == 200) {
          set({ workspaceUsers: result.data });
        }
      },
      getConfigurations: async () => {
        const result = await configurationService.getConfigurations();
        if (result.status == 200) {
          set({ configurations: result.data.configurations });
        }
      },
      addorUpdateConfig: (config: KeyValue) => {
        var configs = get().configurations;
        const index: number = configs.indexOf(config);
        if (index !== -1) {
          configs[index] = config;
          set({ configurations: configs });
        } else {
          set({ configurations: [...get().configurations, config] });
        }
      },
      removeConfig: (key: string) => {
        var configs = get().configurations.filter((x) => x.key !== key);
        set({ configurations: configs });
      },
      getGorgiasSubscriptions: async () => {
        const result = await configurationService.getGorgiasSignatures();
        if (result.status == 200) {
          set({ gorgiasSignatures: result.data });
        }
      },
      addorUpdateGorgiasSubscription: async (sign: GorgiasSignature) => {
        const result = await configurationService.updateGorgiasSignature(sign);
        await get().getGorgiasSubscriptions();
      }
    }))
  )
);
