import React from 'react';
import { TermsAndConditionsText } from './terms';

function TermsAndConditions() {
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: TermsAndConditionsText }} />
    </div>
  );
}

export default TermsAndConditions;
