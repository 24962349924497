import { AxiosResponse } from 'axios';
import { GetCustomerInsightsResponse } from 'src/models/response/GetCustomerInsightsResponse';
import { GetCustomerTrendsResponse } from 'src/models/response/GetCustomerTrendsResponse';
import { ApiService } from './apiService';

export class DashboardService {

  static async getCustomerTrends(range: string[]): Promise<AxiosResponse<GetCustomerTrendsResponse>> {
    return ApiService.api
      .get(`/Dashboard/GetCustomerTrends?from=${range[0]}&to=${range[1]}&timezone=${range[2]}`);
  }

  static async getCustomerInsights(range: string[]): Promise<AxiosResponse<GetCustomerInsightsResponse[]>> {
    return ApiService.api
      .get(`/Dashboard/GetCustomerInsights?from=${range[0]}&to=${range[1]}&timezone=${range[2]}`);
  }

  static async getCustomerImprovements(range: string[]): Promise<AxiosResponse<number[]>> {
    return ApiService.api
      .get(`/Dashboard/GetCustomerImporvementData?from=${range[0]}&to=${range[1]}&timezone=${range[2]}`);
  }
}
