import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);
const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

const RegisterCover = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Cover'))
);
const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);
const RegisterWizard = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Wizard'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const ForgotPasswordConfirmation = Loader(
  lazy(() => import('src/content/pages/Auth/ForgotPasswordConfirmation'))
);

const MicrosoftSignInCallBack = Loader(
  lazy(() => import('src/content/pages/Auth/MicrosoftSignInCallback'))
);
const GoogleSignInCallBack = Loader(
  lazy(() => import('src/content/pages/Auth/GoogleSignInCallback'))
);

const ConfirmRegistration = Loader(
  lazy(() => import('src/content/pages/Auth/ConfirmRegisterCallback'))
);

const EbaySignInCallBack = Loader(
  lazy(() => import('src/content/pages/Auth/EbaySignInCallback'))
);
const GorgiasSignInCallBack = Loader(
  lazy(() => import('src/content/pages/Auth/GorgiasSignInCallback'))
);

const GoogleReviewSignInCallBack = Loader(
  lazy(() => import('src/content/pages/Auth/GoogleReviewSignInCallback'))
);

const TermsAndConditions = Loader(
  lazy(() => import('src/content/pages/Policy/terms'))
);

const Privacy = Loader(lazy(() => import('src/content/pages/Policy/privacy')));
const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterWizard />
      </Guest>
    )
  },
  {
    path: 'login-basic',
    element: <LoginBasic />
  },
  {
    path: 'login-cover',
    element: <LoginCover />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'forgot-password-confirmation',
    element: <ForgotPasswordConfirmation />
  },
  {
    path: 'terms',
    element: <TermsAndConditions />
  },
  {
    path: 'privacy',
    element: <Privacy />
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterCover />
      </Guest>
    )
  },
  {
    path: 'register-basic',
    element: <RegisterBasic />
  },
  {
    path: 'register-cover',
    element: <RegisterCover />
  },
  {
    path: 'register-wizard',
    element: <RegisterWizard />
  },
  {
    path: 'MicrosoftSignInCallback',
    element: <MicrosoftSignInCallBack />
  },
  {
    path: 'GoogleSignInCallback',
    element: <GoogleSignInCallBack />
  },
  {
    path: 'EbaySignInCallback',
    element: <EbaySignInCallBack />
  },  
  {
    path: 'GorgiasSignInCallback',
    element: <GorgiasSignInCallBack />
  },
  {
    path: 'GoogleReviewSignInCallback',
    element: <GoogleReviewSignInCallBack />
  },
  {
    path: 'confirm-email',
    element: <ConfirmRegistration />
  }
];

export default accountRoutes;
