import { AxiosResponse } from 'axios';
import { WorkspaceUserRequest } from 'src/models/request/workspaceRequests';
import { GetWebsitesTypesResponse, SaveWebsitesUrlsResponse, GetWebsitesUrlsResponse } from 'src/models/response/GetWebsitesTypesResponse';
import { Workspace, WorkspaceDetails, WorkspaceUser } from 'src/models/response/WorkspaceReponse';
import { ApiService } from './apiService';

export class WorkspacesService {

  static async getWorkspaces(): Promise<AxiosResponse<Array<Workspace>>> {
    return ApiService.api.get('/Workspace/GetWorkspaces');
  }

  static async getWorkspaceDetails(): Promise<AxiosResponse<WorkspaceDetails>> {
    return ApiService.api.get('/Workspace/GetWorkspaceDetails');
  }

  static async getWorkspaceUsers(): Promise<AxiosResponse<Array<WorkspaceUser>>> {
    return ApiService.api.get('/Workspace/GetWorkspaceUsers');
  }

  static async addWorkspace(wsName: string): Promise<AxiosResponse<Workspace>> {
    return ApiService.api.post('/Workspace/AddWorkspace', {"workspaceName": wsName});
  }

  static async addUserToWorkspace(user: WorkspaceUserRequest): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Workspace/AddUserToWorkspace', user);
  }

  static async removeUserFromWorkspace(userid: string): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Workspace/RemoveUserFromWorkspace', {"UserId": userid});
  }
}
