import { AxiosResponse } from 'axios';
import { SendReplyRequest } from 'src/models/request/sendReplyRequest';
import {
  GetWebsitesTypesResponse,
  SaveWebsitesUrlsResponse,
  GetWebsitesUrlsResponse,
  SaveWebsitesRequest,
  reviewPreference
} from 'src/models/response/GetWebsitesTypesResponse';
import { ApiService } from './apiService';

export class ReviewService {
  static async getWebsitesTypesList(): Promise<
    AxiosResponse<GetWebsitesTypesResponse>
  > {
    return ApiService.api.get('/Websites/GetWebsitesTypes');
  }

  static async saveWebsites(
    collection: SaveWebsitesRequest
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/Websites/SaveWebsites', collection);
  }

  static async AddUrl(
    preference: reviewPreference
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/Websites/AddUrl', preference);
  }

  static async getUserWebsites(): Promise<
    AxiosResponse<GetWebsitesUrlsResponse>
  > {
    return ApiService.api.get('/Websites/GetUserWebsites');
  }

  static async RemoveUrl(url: string): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/Websites/RemoveUrl', {
      Name: '',
      type: '',
      Url: url
    });
  }

  static async getLatestReviews(
    sourceType: string
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.get(
      `/Websites/GetLatestReviews?sourceType=${sourceType}`
    );
  }

  static async archive(reviewIds: string[]): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Websites/ArchiveReviews', reviewIds);
  }

  static async MarkAsOpened(
    reviewIds: string[]
  ): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Websites/MarkAsOpened', reviewIds);
  }

  static async MarkAsAnswered(
    reviewIds: string[]
  ): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Websites/MarkAsAnswered', reviewIds);
  }

  static async SendGoogleReviewReply(
    reply: SendReplyRequest
  ): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Websites/SendGoogleReviewReply', reply);
  }

  static async SendEbayReply(
    reply: SendReplyRequest
  ): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Websites/SendEbayReply', reply);
  }
}
