import { SetMealSharp } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { getDate } from "date-fns";
import { Tag } from "src/models/mailbox";
import { GenerateReplyRequest } from "src/models/request/generateReplyRequest";
import { PagingParams } from "src/models/request/pagination";
import { SendReplyRequest } from "src/models/request/sendReplyRequest";
import { BoardsGetEmailDataResponse, SnackBarSettings } from "src/models/response/BoardsGetDataResponse";
import { Template } from "src/models/response/TemplateResponse";
import { BoardsService } from "src/services/boardsService";
import { EmailService } from "src/services/emailService";
import create from 'zustand'
import "quill-paste-smart";
import { devtools, persist } from 'zustand/middleware'
import { GmailService } from "src/services/gmailService";

export enum Status {
  New,
  InProgress,
  Resolved
}
export enum Trend {
  Unchanged,
  Improving,
  Escalating
}
export interface SelectInput {
  title: string
}
export interface Draft {
  id: string,
  draft: string
}
const trends = [
  { title: 'Unchanged' },
  { title: 'Improving' },
  { title: 'Escalating' },
];

const status = [
  { title: 'New' },
  { title: 'In Progress' },
  { title: 'Resolved' },
];
export interface EmailStore {
  singleEmail: BoardsGetEmailDataResponse,
  emails: BoardsGetEmailDataResponse[],

  pageNumber: number,
  total: number,
  timezone: string,
  pageSize: number,
  ids: string,
  sideBarOpen: boolean,

  autoRefresh: boolean,

  //filters
  tags: Tag[],
  trends: SelectInput[],
  status: SelectInput[],
  startDate: string,
  endDate: string,
  searchString: string,
  sortBy: string,
  drafts: Draft[],
  generatingReply: boolean,
  showDraft: boolean,
  //actions
  setEmails: () => void,
  setStartDate: (startDate: string) => void,
  setEndDate: (startDate: string) => void,
  setSideBarOpen: (value: boolean) => void,
  setSingleEmail: (id: string) => void,
  updateStatus: (id: string, status: string) => void,
  updateDraft: (id: string, draft: string) => void,
  updateShowDraft: (id: string, value: boolean) => void,
  setFitlerStatus: (statuses: SelectInput[]) => void,
  setFitlerTrends: (statuses: SelectInput[]) => void,
  setFitlerSearchString: (statuses: string) => void,
  setAutoRefresh: (autorefresh: boolean) => void,
  sendReply: (reply: SendReplyRequest, provider: string) => void,
  generateReply: (mailId: string) => void,
  setSort: (value: string) => void,
  MarkAsOpened: (value: string[]) => void,
  archiveEmails: (value: string[]) => void,
  updatepageNumber: (value: number) => void,
  useTemplate: (mailId: string, body: string) => void,
  updateGeneratingReply: (id: string, value: boolean) => void,


  assign: (id: string, toUserId: string) => void
}

export const useEmailStore = create<EmailStore>()(devtools(
  persist((set, get) => ({
    singleEmail: null,
    emails: null,
    status: [],
    startDate: (() => {
      var d = new Date();
      d.setMonth(d.getMonth() - 1)
      return d;
    })().toISOString(),
    endDate: (new Date()).toDateString(),
    pageNumber: 1,
    total: 0,
    timezone: '',
    pageSize: 10,
    ids: '00000000-0000-0000-0000-000000000000',

    //filter
    trends: [],
    searchString: '',
    sortBy: 'Sent Date Time desc',
    autoRefresh: true,

    sideBarOpen: false,
    drafts: [],
    generatingReply: false,
    showDraft: false,
    tags: [{
      id: 'mails',
      type: 'heading',
      name: 'Mails',
    },
    {
      id: 'inbox',
      type: 'category_tag',
      name: 'Inbox',
      newMails: 7
    },
    {
      id: 'sent',
      type: 'category_tag',
      name: 'Sent',
      newMails: 0
    },
    {
      id: 'statuses',
      type: 'heading',
      name: 'Statuses',
    },
    {
      id: 'new',
      type: 'label_tag',
      name: 'New',
      newMails: 0,
      color: 'green'
    },
    {
      id: 'inprogress',
      type: 'label_tag',
      name: 'In Progress',
      newMails: 0,
      color: 'green'
    },
    {
      id: 'resolved',
      type: 'label_tag',
      name: 'Resolved',
      newMails: 1,
      color: 'green'
    },
    {
      id: 'trend',
      type: 'heading',
      name: 'Trends',
    },
    {
      id: 'escalating',
      type: 'label_tag',
      name: 'Escalating',
      newMails: 4,
      color: '#FF1943',
    },
    {
      id: 'unchanged',
      type: 'label_tag',
      name: 'Unchaged',
      newMails: 3,
      color: '#FFA319'
    },
    {
      id: 'improving',
      type: 'label_tag',
      name: 'Improving',
      newMails: 3,
      color: '#57CA22'
    },],
    setEmails: async () => {

      const filter: PagingParams = {
        endDate: get().endDate,
        pageNumber: get().pageNumber,
        pageSize: get().pageSize,
        ids: '00000000-0000-0000-0000-000000000000',
        startDate: get().startDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        trends: get().trends,
        status: get().status,
        searchString: get().searchString,
        sortBy: get().sortBy,
        sourceType: ''
      };
      const result = await BoardsService.getEmailData(filter)
      set({ total: result.data.total });
      var mailResult = result.data.emails.map((mail) => {
        mail.tagIds = [];
        if (mail.performance === 0) mail.tagIds = [...mail.tagIds, 'unchanged']
        if (mail.performance === 1) mail.tagIds = [...mail.tagIds, 'improving']
        if (mail.performance === 2) mail.tagIds = [...mail.tagIds, 'escalating']
        if (mail.label === 0) mail.tagIds = [...mail.tagIds, 'happy']
        if (mail.label === 1) mail.tagIds = [...mail.tagIds, 'unhappy']
        if (mail.label === 2) mail.tagIds = [...mail.tagIds, 'neutral']
        mail.category = 'inbox';
        mail.emailChain.map((chain) => {
          chain.tagIds = [];
          if (chain.label === 0) chain.tagIds = [...chain.tagIds, 'happy']
          if (chain.label === 1) chain.tagIds = [...chain.tagIds, 'unhappy']
          if (chain.label === 2) chain.tagIds = [...chain.tagIds, 'neutral']
        })
        return mail;
      });

      set({ emails: mailResult })
    },
    setStartDate: (value) => {
      set({ startDate: value })
    },
    setEndDate: (value) => {
      set({ endDate: value })
    },
    setSideBarOpen: (value) => {
      set({ sideBarOpen: value })
    },
    setSingleEmail: (id) => {
      if (get().emails !== null && id !== undefined) {

        const singlemail = get().emails.filter(x => x.id == id);
        set({ singleEmail: singlemail[0] });
      }
    },
    updateStatus: async (id, status) => {
      if (get().emails !== null && id !== undefined) {

        let currentEmails = get().emails.map((e) =>
          e.id === id ?
            {

              ...e,
              status: status

            }
            : e

        );
        set({ emails: currentEmails });
        await BoardsService.UpdateStatus(status, id);
      }

    },
    setSort: (value) => {
      set({ sortBy: value })
    },
    updateDraft: (id, draft) => {
      if (get().emails !== null && id !== undefined) {

        let currentEmails = get().emails.map((e) =>
          e.id === id ?
            {
              ...e,
              draft: draft
            }
            : e

        );
        var single = get().singleEmail
        single.draft = draft;
        set({ emails: currentEmails });
        set({ singleEmail: single })
      }
    },
    
    updateGeneratingReply: (id, value) => {
      if (get().emails !== null && id !== undefined) {

        let currentEmails = get().emails.map((e) =>
          e.id === id ?
            {
              ...e,
              generatingReply: value
            }
            : e

        );
        var single = get().singleEmail
        single.generatingReply = value;
        set({ emails: currentEmails });
        set({ singleEmail: single })
      }
    },
    updateShowDraft: (id, value) => {
      if (get().emails !== null && id !== undefined) {

        let currentEmails = get().emails.map((e) =>
          e.id === id ?
            {
              ...e,
              showDraft: value
            }
            : e

        );
        var single = get().singleEmail
        single.showDraft = value;
        set({ emails: currentEmails });
        set({ singleEmail: single })
      }
    },
    setFitlerStatus: (statuses) => {
      set({ status: statuses })
    },
    setFitlerTrends: (value) => {
      set({ trends: value })
    },
    setFitlerSearchString: (value) => {
      set({ searchString: value })
    },
    setAutoRefresh: (autorefresh) => {
      set({ autoRefresh: autorefresh })
    },
    sendReply: (reply: SendReplyRequest,  provider: string) => {
      if(provider === 'Microsoft')
      {

        BoardsService.sendReply(reply);
      }
      if(provider === 'Google')
      {
        GmailService.sendReply(reply)
      }
    },
    generateReply: async (mailId: string) => {
      get().updateGeneratingReply(mailId, true);
      const request: GenerateReplyRequest = {
        MailId: mailId,
        Message: 'message'
      }
      const generateReply = await BoardsService.generateReply(request);

      if (get().emails !== null && generateReply.status === 200) {

        let currentEmails = get().emails.map((e) =>
          e.id === mailId ?
            {
              ...e,
              generateReplay: generateReply.data
            }
            : e
        );
        var single = get().singleEmail
        set({ emails: currentEmails });
        single.generateReplay = generateReply.data;
        set({ singleEmail: single })
        get().updateShowDraft(mailId, true)
      }


      get().updateGeneratingReply(mailId, false);
    },
    MarkAsOpened: async (value: string[]) => {
      value.forEach(mailId => {
        if (get().emails !== null && mailId !== undefined) {

          let currentEmails = get().emails.map((e) =>
            e.objectId === mailId ?
              {
                ...e,
                opened: true
              }
              : e
          );
          set({ emails: currentEmails });
        }
      });

      EmailService.MarkAsOpened(value);
    },
    archiveEmails: async (value: string[]) => {
      value.forEach(mailId => {
        var filteredEmails = get().emails.filter(x => x.objectId !== mailId);
        set({ emails: filteredEmails })
        filteredEmails = get().emails.filter(x => x.id !== mailId);
        set({ emails: filteredEmails })
      });

      await EmailService.archiveEmails(value);
    },

    updatepageNumber: (value: number) => {
      set({ pageNumber: value })
    },

    assign: async (id: string, toUserId: string) => {
      var result = await BoardsService.assign(id, toUserId, 3); // 3 is for outlook
      if (result.status == 200) {
        get().setEmails();
      }
    },

    useTemplate: async (mailId: string, body: string) => {


      if (get().emails !== null) {

        let currentEmails = get().emails.map((e) =>
          e.id === mailId ?
            {
              ...e,
              generateReplay: body
            }
            : e

        );
        var single = get().singleEmail
        set({ emails: currentEmails });
        single.generateReplay = body;
        set({ singleEmail: single })
        get().updateShowDraft(mailId, true);
      }
    },
    setShowDraft: (value: boolean) => {
      set({showDraft: value});
    },
  }
  ))));