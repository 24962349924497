import { AxiosResponse } from 'axios';
import { SendReplyRequest } from 'src/models/request/sendReplyRequest';
import { EmailSyncFilter, UserEmailPreferences } from 'src/models/response/GetEmailFilterResponse';
import { ApiService } from './apiService';

export class GmailService {
  static getSendersEmailAddresses(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/Gmail/GetSendersEmailAddresses', { searchQuery, pageSize: pageSize || null });
  }

  static async getEmailAddresses(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/Gmail/GetEmailAddresses', { searchQuery, pageSize: pageSize || null });
  }

  static async getEmailAddressesFromCopy(
    searchQuery: string,
    pageSize?: number,
  ): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/Gmail/GetEmailAddressesFromCopy', { searchQuery, pageSize: pageSize || null });
  }

  static async getTags(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/Gmail/GetTags', { searchQuery, pageSize: pageSize || null });
  }

  static async saveEmailSyncFilter(filter: EmailSyncFilter
  ): Promise<AxiosResponse<{ reasonPhrase: string }>> {
    return ApiService.api.post(
      '/Gmail/SaveEmailSyncFilter',
      filter,
    );
  }

  static async saveUserGmailPreferences(
    preferences: UserEmailPreferences
  ): Promise<AxiosResponse<{ reasonPhrase: string }>> {
    return ApiService.api.post(
      '/Gmail/SaveUserGmailPreferences',
      preferences,
    );
  }
  
  static async sendReply(reply: SendReplyRequest): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Gmail/SendReply', reply);
  }

  static async getEmailSyncFilter(): Promise<AxiosResponse<EmailSyncFilter>> {
    return ApiService.api.get('/Gmail/GetEmailSyncFilter');
  }

  static async getUserGmailPreferences(): Promise<AxiosResponse<UserEmailPreferences>> {
    return ApiService.api.get('/Gmail/GetUserGmailPreferences');
  }
  
  static async archiveEmails(emailIds: string[]): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Gmail/ArchiveEmails', emailIds);
  }

  static async MarkAsOpened(emailIds: string[]): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/Gmail/MarkAsOpened', emailIds);
  }
}
