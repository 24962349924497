import { AxiosResponse } from 'axios';
import {
  ConfigurationRequest,
  KeyValue
} from 'src/models/request/configurationRequests';
import { ApiResponse } from 'src/models/response/AuthResponse';
import { ApiService } from './apiService';
import { GorgiasSignature } from 'src/store/usersStore';

export class configurationService {
  static async getConfigurations(): Promise<
    AxiosResponse<ConfigurationRequest>
  > {
    return ApiService.api.get(`/Configuration/GetConfigurations`);
  }

  static async updateConfigurations(
    configs: KeyValue[]
  ): Promise<AxiosResponse<ApiResponse>> {
    return ApiService.api.post('/Configuration/AddOrUpdateConfigurations', {
      configurations: configs
    });
  }

  static async addConfiguration(
    config: KeyValue
  ): Promise<AxiosResponse<ApiResponse>> {
    return ApiService.api.post('/Configuration/AddConfig', config);
  }

  static async updateConfig(
    config: KeyValue
  ): Promise<AxiosResponse<ApiResponse>> {
    return ApiService.api.post('/Configuration/UpdateConfig', config);
  }

  static async deleteConfig(key: string): Promise<AxiosResponse<ApiResponse>> {
    return ApiService.api.post('/Configuration/DeleteConfig', {
      Key: key,
      Value: ''
    });
  }

  static async getGorgiasSignatures(): Promise<
    AxiosResponse<GorgiasSignature[]>
  > {
    return ApiService.api.get(`/Gorgias/GetGorgiasSignatures`);
  }

  static async updateGorgiasSignature(
    sign: GorgiasSignature
  ): Promise<AxiosResponse<ApiResponse>> {
    return ApiService.api.post('/Gorgias/AddOrUpdateGorgiasSignature', sign);
  }
}
