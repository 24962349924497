import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuthStore } from 'src/store/authStore';
import { AuthService } from './authService';
export enum StorageKeys {
  Token = '[Storage] Token',
  User = '[User] info'
}
export class ApiService {
  static api: AxiosInstance;

  static init(baseUrl: string): void {

    const authStore = useAuthStore((state) => state);
    const updateSuscriptionExpiry = useAuthStore((state) => state.updateSuscriptionExpiry)

    ApiService.api = axios.create({
      baseURL: baseUrl,
    });
    ApiService.api.interceptors.request.use((config: AxiosRequestConfig) => {
      const tokens = JSON.parse(localStorage.getItem(StorageKeys.Token));
      if(authStore.isAuthenticated){
        updateSuscriptionExpiry();
      }
      if (tokens) {
        const { token } = tokens;
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    ApiService.api.interceptors.response.use(
      response => {
        
        return response;
      },
      (error: any) => {
        const { status } = error.response;
        const { config } = error;
        if (status === 401) {
          const tokens = JSON.parse(localStorage.getItem(StorageKeys.Token));
          if(!config._retry){
          
          config._retry = true;
            if(tokens){
          return AuthService.regenerateNewTokens(tokens.refreshToken)
            .then(payload => {
              const { accessToken, refreshToken } = payload.data;
              localStorage.setItem(StorageKeys.Token, JSON.stringify({token: accessToken, refreshToken}));
              authStore.setToken(accessToken, refreshToken);
              config.headers.Authorization = `Bearer ${payload?.data?.accessToken}`;
              return ApiService.api(config);
            })
            .catch(error => {
              Promise.reject(error)
            });
            }
        }
        authStore.logout()
        }
        return Promise.reject(error);
      },
    )
  }
}
