import { AxiosResponse } from "axios";
import { getDate } from "date-fns";
import { Tag } from "src/models/mailbox";
import { GenerateReplyRequest } from "src/models/request/generateReplyRequest";
import { PagingParams } from "src/models/request/pagination";
import { SendReplyRequest } from "src/models/request/sendReplyRequest";
import { BoardsGetEmailDataResponse } from "src/models/response/BoardsGetDataResponse";
import { CustomerInsightsResponse, GetCustomerInsightsResponse } from "src/models/response/GetCustomerInsightsResponse";
import { GetCustomerTrendsResponse, GetReviewTrendsResponse } from "src/models/response/GetCustomerTrendsResponse";
import { AvgCalculatingService } from "src/services/avgCalculatingService";
import { BoardsService } from "src/services/boardsService";
import { DashboardService } from "src/services/dashboardService";
import { EmailService } from "src/services/emailService";
import { ReviewDashboardService } from "src/services/ReviewDashboardService";
import create from 'zustand'

import { devtools, persist } from 'zustand/middleware'

export enum Status{
    New,
    InProgress,
    Resolved
}
export enum Trend{
  Unchanged,
  Improving,
  Escalating
}
export interface ReviewDashboardStore{

  startDate: string,
  endDate: string,
  AverageInteractions: number,
  AverageResolutionTime: string,
  AverageTimeToFirstReply: string,
  reviewTrends: GetReviewTrendsResponse[],
  customerImprovements: number[],
  customerInsights: CustomerInsightsResponse[];

  //actions
  setStartDate: (value: string) => void,
  setEndDate: (value: string) => void,
  getAverage: () => void,
  getReviewHistory: () => void,
  getCustomerImprovements: () => void,
  getCustomerinsights: () => void,

}
export const mapAvgCalculatingData = (avgData: string): string => {
  if (avgData) {
    const array = avgData.split(':');
    const [days, hours] = array[0].split('.');
    const daysString = days === "0"? '':`${days}d`;
    const hoursString = hours === "00"? '':`${hours}h`;
    const minsString = array[1] === "00"? '':`${array[1]}m`;
    const secsString = array[2] === "00"? '':`${array[2]}s`;

    let displayString = `${daysString} ${hoursString} ${minsString} ${secsString}`;
    if(displayString.trim() === '')
    displayString = 'NA'
    return displayString;
  }
};
export const useReviewDashboardStore = create<ReviewDashboardStore>()(devtools(
 
    persist((set, get) => ({
      
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() -1)
        return d;
      })().toISOString(),
      endDate: (new Date()).toISOString(),

      AverageInteractions: 0,
      AverageResolutionTime: '0',
      AverageTimeToFirstReply: '0',
      reviewTrends: [],
      customerImprovements: null,
      customerInsights:null,
      //actions
      setStartDate: (value) => {
        set({startDate: value})
      },
      setEndDate: (value) => {
        set({endDate: value})
      },
      getAverage: async () => {
        const result = await AvgCalculatingService.getAverage([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){

          set({AverageInteractions: Math.round(result.data.averageInteractions)})
          set({AverageResolutionTime: mapAvgCalculatingData(result.data.averageResolutionTime)})
          set({AverageTimeToFirstReply: mapAvgCalculatingData(result.data.averageTimeToFirstReply)})
        }
      },
      getReviewHistory: async () => {
        const result = await ReviewDashboardService.getReviewHistory([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){
          set({reviewTrends: result.data})
        }
      },
      
      getCustomerImprovements: async () => {
        const result = await DashboardService.getCustomerImprovements([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){
          set({customerImprovements: result.data})
        }
      },
      getCustomerinsights: async () => {
        const result = await ReviewDashboardService.getCustomerInsights([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone, ]);
        if(result.status === 200){
          set({customerInsights: result.data})
        }
      }
}
))));