import { AxiosResponse } from 'axios';
import { GenerateReplyRequest } from 'src/models/request/generateReplyRequest';
import { PagingParams } from 'src/models/request/pagination';
import { SendReplyRequest } from 'src/models/request/sendReplyRequest';
import { BoardsGetEmailDataResponse, BoardsGetDocumentDataResponse, BoardsGetUrlParsingDataResponse, EmailsReponse, ReviewsReponse } from 'src/models/response/BoardsGetDataResponse';

import { ApiService } from './apiService';

export class BoardsService {
  static async getEmailData(filter: PagingParams
     ): Promise<AxiosResponse<EmailsReponse>> {
    return ApiService.api.post('/RiskBoard/GetEmailData/', filter);
  }

  static async getDocumentData(
    startDate: string,
    endDate: string,
    timeZone: string,
    pageNumber = 1,
    pageSize = 25,
    ids = '00000000-0000-0000-0000-000000000000'
  ): Promise<AxiosResponse<Array<BoardsGetDocumentDataResponse>>> {
    return ApiService.api.get(`/RiskBoard/GetDocumentData/?PageNumber=
    ${pageNumber}&PageSize=${pageSize}&Ids=${ids}&From=
    ${startDate}&To=${endDate}&Timezone=${timeZone}`);
  }

  static async getUrlParsingData(filter: PagingParams): Promise<AxiosResponse<ReviewsReponse>> {
    return ApiService.api.post('/RiskBoard/GetUrlParsingData/', filter);
  }

  
  static async generateReply(message: GenerateReplyRequest): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/RiskBoard/GenerateReply', message);
  }
  
  static async generateReviewReply(message: GenerateReplyRequest): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/RiskBoard/GenerateReviewReply', message);
  }

  static async getGeneratedReply(id: string): Promise<AxiosResponse<string>> {
    return ApiService.api.get(`/RiskBoard/GetGeneratedReply/?mailid=${id}`);
  }

  static async getStatuses(): Promise<AxiosResponse<Array<string>>>{
    return ApiService.api.get('/RiskBoard/GetStatuses');
  }

  static async UpdateStatus(statusId: string, mlResponseId: string): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/RiskBoard/UpdateStatus', {StatusId:statusId, MlResponseId: mlResponseId});
  }

  static async sendReply(reply: SendReplyRequest): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/RiskBoard/SendReply', reply);
  }

  static async assign(id: string, toUserId: string, sourceType: number): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/RiskBoard/Assign', {id: id, toUserId: toUserId, sourceType: sourceType});
  }
}
