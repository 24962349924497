import { AxiosResponse } from "axios";
import { AddTemplateRequestModel, RemoveTemplateRequest, UpdateTemplateRequestModel } from "src/models/request/templateRequest";
import { ApiResponse } from "src/models/response/AuthResponse";
import { Template } from "src/models/response/TemplateResponse";
import { ApiService } from "./apiService";

export class templateService{
    static async getTemplates(sourceType: number): Promise<AxiosResponse<Array<Template>>> {
        return ApiService.api.get(`/Template/GetTemplates?sourceType=${sourceType}`);
    }

    static async addTemplate(template: AddTemplateRequestModel): Promise<AxiosResponse<ApiResponse>> {
        return ApiService.api.post('/Template/AddTemplate', template);
      }

      static async updateTemplate(template: UpdateTemplateRequestModel): Promise<AxiosResponse<string>> {
        return ApiService.api.post('/Template/UpdateTemplate', template);
      }

      static async removeTemplate(template: RemoveTemplateRequest): Promise<AxiosResponse<void>> {
        return ApiService.api.post('/Template/RemoveTemplate', template);
      }
}