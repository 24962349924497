import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from 'src/content/pages/Auth/Login/Cover';
import { useAuthStore } from 'src/store/authStore';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const authStore = useAuthStore((state) => state);

  

  if (!authStore.isAuthenticated) {

    return <Login />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
