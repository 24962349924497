import { AxiosResponse } from "axios";
import { getDate } from "date-fns";
import { Tag } from "src/models/mailbox";
import { GenerateReplyRequest } from "src/models/request/generateReplyRequest";
import { PagingParams } from "src/models/request/pagination";
import { SendReplyRequest } from "src/models/request/sendReplyRequest";
import { BoardsGetEmailDataResponse } from "src/models/response/BoardsGetDataResponse";
import { GetCustomerTrendsResponse } from "src/models/response/GetCustomerTrendsResponse";
import { AvgCalculatingService } from "src/services/avgCalculatingService";
import { BoardsService } from "src/services/boardsService";
import { DashboardService } from "src/services/dashboardService";
import { EmailService } from "src/services/emailService";
import create from 'zustand'

import { devtools, persist } from 'zustand/middleware'

export enum Status{
    New,
    InProgress,
    Resolved
}
export enum Trend{
  Unchanged,
  Improving,
  Escalating
}
export interface SelectInput{
  title: string
}
const trends = [
  { title: 'Unchanged' },
  { title: 'Improving' },
  { title: 'Escalating' },
];

const status = [
  { title: 'New' },
  { title: 'In Progress' },
  { title: 'Resolved' },
];
export interface DashboardStore{

  startDate: string,
  endDate: string,
  AverageInteractions: number,
  AverageResolutionTime: string,
  AverageTimeToFirstReply: string,
  customerTrends: GetCustomerTrendsResponse,
  customerImprovements: number[],

  //actions
  setStartDate: (value: string) => void,
  setEndDate: (value: string) => void,
  getAverage: () => void,
  getCustomerTrends: () => void,
  getCustomerImprovements: () => void,

}
export const mapAvgCalculatingData = (avgData: string): string => {
  if (avgData) {
    const array = avgData.split(':');
    const [days, hours] = array[0].split('.');
    const daysString = days === "0"? '':`${days}d`;
    const hoursString = hours === "00"? '':`${hours}h`;
    const minsString = array[1] === "00"? '':`${array[1]}m`;
    const secsString = array[2] === "00"? '':`${array[2]}s`;

    let displayString = `${daysString} ${hoursString} ${minsString} ${secsString}`;
    if(displayString.trim() === '')
    displayString = 'NA'
    return displayString;
  }
};
export const useDashboardStore = create<DashboardStore>()(devtools(
 
    persist((set, get) => ({
      
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() -1)
        return d;
      })().toISOString(),
      endDate: (new Date()).toISOString(),

      AverageInteractions: 0,
      AverageResolutionTime: '0',
      AverageTimeToFirstReply: '0',
      customerTrends: null,
      customerImprovements: null,
      //actions
      setStartDate: (value) => {
        set({startDate: value})
      },
      setEndDate: (value) => {
        set({endDate: value})
      },
      getAverage: async () => {
        const result = await AvgCalculatingService.getAverage([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){

          set({AverageInteractions: Math.round(result.data.averageInteractions)})
          set({AverageResolutionTime: mapAvgCalculatingData(result.data.averageResolutionTime)})
          set({AverageTimeToFirstReply: mapAvgCalculatingData(result.data.averageTimeToFirstReply)})
        }
      },
      getCustomerTrends: async () => {
        const result = await DashboardService.getCustomerTrends([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){
          set({customerTrends: result.data})
        }
      },
      
      getCustomerImprovements: async () => {
        const result = await DashboardService.getCustomerImprovements([get().startDate.toString(),get().endDate.toString(),Intl.DateTimeFormat().resolvedOptions().timeZone]);
        if(result.status === 200){
          set({customerImprovements: result.data})
        }
      }
}
))));