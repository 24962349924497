import { AddTemplateRequestModel, RemoveTemplateRequest, UpdateTemplateRequestModel } from "src/models/request/templateRequest";
import { Template } from "src/models/response/TemplateResponse";
import { templateService } from "src/services/templateService";
import { StringMappingType } from "typescript";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useEmailStore } from "./emailStore";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';


export interface templateStore{
    emailTemplates: Template[],
    reviewTemplates: Template[],

    getTemplates: (sourceType: number) => void,
    AddTemplate: (template: AddTemplateRequestModel) => void,
    UpdateTemplate: (template: UpdateTemplateRequestModel) => void,
    RemoveTemplate: (template: RemoveTemplateRequest) => void,
}

export const useTemplateStore = create<templateStore>()(devtools(
    persist((set, get) => ({
    emailTemplates: [],
    reviewTemplates: [],

    getTemplates: async (sourceType: number) => {
        const result = await templateService.getTemplates(sourceType);
        if(sourceType == 0){
            set({emailTemplates: result.data})
        }
        if(sourceType == 2){
            set({reviewTemplates: result.data})
        }
    },

    AddTemplate: async (template: AddTemplateRequestModel) =>  {
        
        const result = await templateService.addTemplate(template);
        get().getTemplates(template.sourceType)
        
    },
    UpdateTemplate: async (template: UpdateTemplateRequestModel) => {
        await templateService.updateTemplate(template);
        get().getTemplates(template.sourceType)
    },
    RemoveTemplate: async (template: RemoveTemplateRequest) => {
        await templateService.removeTemplate(template);
        get().getTemplates(template.sourceType)
    },
}
))));