import { AxiosResponse } from 'axios';
import { WorkspaceUserRequest } from 'src/models/request/workspaceRequests';
import { GetWebsitesTypesResponse, SaveWebsitesUrlsResponse, GetWebsitesUrlsResponse } from 'src/models/response/GetWebsitesTypesResponse';
import { PaymentUpdateResponse } from 'src/models/response/PaymentResponse';
import { SubscriptionResoponse } from 'src/models/response/subscriptionResponse';
import { Workspace, WorkspaceDetails, WorkspaceUser } from 'src/models/response/WorkspaceReponse';
import { GetPayemtResponse, UserResponse } from 'src/models/user';
import { ApiService } from './apiService';

export class userService {

  static async getUsers(): Promise<AxiosResponse<Array<UserResponse>>> {
    return ApiService.api.get('/User/GetUsers');
  }

  static async getPayments(): Promise<AxiosResponse<Array<GetPayemtResponse>>> {
    return ApiService.api.get('/User/GetPaymentHistory');
  }

  static async updateRoles(roles: string, userid: string): Promise<AxiosResponse<WorkspaceDetails>> {
    return ApiService.api.post('/User/UpdateRoles', {roles: roles, userId: userid});
  }

  static async PaymentUpdate(roles: string): Promise<AxiosResponse<PaymentUpdateResponse>> {
    return ApiService.api.post('/User/PaymentUpdate', {Roles: roles, CurrentDateTime: (new Date()).toISOString() });
  }
  
  static async GetsubscriptionDetails(): Promise<AxiosResponse<SubscriptionResoponse>> {
    return ApiService.api.get('/User/GetUserSubscription');
  }
}
