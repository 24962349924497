
import { AxiosResponse } from 'axios';
import { AverageResponse } from 'src/models/response/AverageResponse';
import { ApiService } from './apiService';

export class AvgCalculatingService {

  static async getAvgNumberOfInteractions(range: string[]): Promise<AxiosResponse<number>> {
    return ApiService.api.get(`/AvgCalculating/GetAvgNumberOfInteractions?startDate=${range[0]}&endDate=${range[1]}`);
  }

  static async getAvgTimeToFirstReply(range: string[]): Promise<AxiosResponse<string>> {
    return ApiService.api.get(`/AvgCalculating/GetAvgTimeToFirstReply?startDate=${range[0]}&endDate=${range[1]}`);
  }

  static async getAvgResolutionTime(range: string[]): Promise<AxiosResponse<string>> {
    return ApiService.api.get(`/AvgCalculating/GetAvgResolutionTime?startDate=${range[0]}&endDate=${range[1]}`);
  }

  static async getAverage(range: string[]): Promise<AxiosResponse<AverageResponse>> {
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return ApiService.api.get(`/AvgCalculating/GetAverage?startDate=${range[0]}&endDate=${range[1]}&timezone=${timeZoneName}`);
  }
}
