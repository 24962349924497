import { AxiosResponse } from 'axios';
import { EmailSyncFilter, UserEmailPreferences } from 'src/models/response/GetEmailFilterResponse';
import { ApiService } from './apiService';

export class EmailService {
  static getSendersEmailAddresses(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/OutlookEmail/GetSendersEmailAddresses', { searchQuery, pageSize: pageSize || null });
  }

  static async getEmailAddresses(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/OutlookEmail/GetEmailAddresses', { searchQuery, pageSize: pageSize || null });
  }

  static async getEmailAddressesFromCopy(
    searchQuery: string,
    pageSize?: number,
  ): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/OutlookEmail/GetEmailAddressesFromCopy', { searchQuery, pageSize: pageSize || null });
  }

  static async getTags(searchQuery: string, pageSize?: number): Promise<AxiosResponse<Array<string>>> {
    return ApiService.api.post('/OutlookEmail/GetTags', { searchQuery, pageSize: pageSize || null });
  }

  static async saveEmailSyncFilter(filter: EmailSyncFilter
  ): Promise<AxiosResponse<{ reasonPhrase: string }>> {
    return ApiService.api.post(
      '/OutlookEmail/SaveEmailSyncFilter',
      filter,
    );
  }

  static async saveUserOutlookPreferences(
    preferences: UserEmailPreferences
  ): Promise<AxiosResponse<{ reasonPhrase: string }>> {
    return ApiService.api.post(
      '/OutlookEmail/SaveUserOutlookPreferences',
      preferences,
    );
  }

  static async getEmailSyncFilter(): Promise<AxiosResponse<EmailSyncFilter>> {
    return ApiService.api.get('/OutlookEmail/GetEmailSyncFilter');
  }

  static async getUserOutlookPreferences(): Promise<AxiosResponse<UserEmailPreferences>> {
    return ApiService.api.get('/OutlookEmail/GetUserOutlookPreferences');
  }
  
  static async archiveEmails(emailIds: string[]): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/OutlookEmail/ArchiveEmails', emailIds);
  }

  static async MarkAsOpened(emailIds: string[]): Promise<AxiosResponse<boolean>> {
    return ApiService.api.post('/OutlookEmail/MarkAsOpened', emailIds);
  }
}
