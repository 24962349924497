import { mock } from 'src/utils/axios';
import type { User } from 'src/models/user';

let users: User[] = [
  {
    id: '1',
    name: 'Rafael Kunde',
    avatar: '/static/images/avatars/1.jpg',
    email: 'Monte.Auer31@yahoo.com',
    jobtitle: 'Product Infrastructure Associate',
    username: 'Delphia22',
    location: 'Gislasonchester',
    role: 'admin',
    coverImg: '/static/images/placeholders/covers/1.jpg',
    followers: '667',
    description:
      'Vestibulum rutrum rutrum neque. Aenean auctor gravida sem quam pede lobortis ligula, sit amet eleifend.',
    posts: '8',
    plan: 'starter',
    noOfIneractionsUsed:0,
    noOfMaxInteractions:0,
  },
];

mock.onGet('/api/users').reply(() => {
  return [200, { users }];
});

mock.onGet('/api/user').reply((config) => {
  const { userId } = config.params;
  const user = users.find((_user) => _user.id === userId);

  return [200, { user }];
});
